import { createStore } from 'vuex'

export function useAuthStore() {
  const nuxtApp = useNuxtApp()
  const store = createStore({
    modules: {
      auth: nuxtApp.$auth
    }
  })
  return store
}
